import Swal from 'sweetalert2/dist/sweetalert2.js';

export default {
    methods: {
        async SwalDelete(
            title = 'Are you sure?',
            message = 'Are you sure you want to delete this item?'
        ) {
            let res = await Swal.fire({
                title: title,
                text: message,
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#dd6b55',
                cancelButtonColor: '#aaa',
                confirmButtonText: 'Yes, delete it!',
                reverseButtons: true
            });

            return res.isConfirmed;
        }
    }
}