<template>
    <nav>
        <button class="btn btn-action" type="button" @click="toggleSidebar()">
            <i class="fa fa-bars" aria-hidden="true"></i>
        </button>
        <span class="navbar-text ml-20">
            <span class="text-monospace">I N V E N T <img src="../assets/logo.png" style="width: 18px;" /> R Y</span>
        </span>
        <div class="navbar-content ml-auto">
            <button class="btn btn-light mr-5" type="button" @click="toggleDarkMode()"><i class="fa fa-moon"></i></button>
            <button class="btn btn-danger" type="button" @click="logout()"><i class="fa fa-power-off"></i></button>
        </div>
    </nav>
</template>
<script>
import * as halfmoon from 'halfmoon';
import Swal from "../mixins/Swal";
import Toast from "../mixins/Toast";

var r = document.querySelector(':root');

export default {
    name: 'navbar',
    mixins: [ Swal, Toast ],
    data() {
        return {
            isShow: true,
        }
    },
    methods: {
        toggleSidebar() {
            this.isShow = !this.isShow;
            if (this.isShow) {
                r.style.setProperty('--sidebar-width', '300px');
                return;
            }
            r.style.setProperty('--sidebar-width', '0');
        },
        toggleDarkMode() {
            halfmoon.toggleDarkMode();
        },
        logout() {
            this.ToastSuccess('You logout successfully!');
            localStorage.removeItem('access_token');
            setTimeout(() => {
                this.$router.push('/login')
            }, 500);
        }
    }
}
</script>