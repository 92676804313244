import { createRouter, createWebHistory } from 'vue-router';
import Nprogress from 'nprogress';
import 'nprogress/nprogress.css';
import { isAuthenticated } from '../helpers'

async function asyncComponent(importComponent) {
    Nprogress.start();
    let data = await importComponent();
    Nprogress.done();
    return data;
}

const routes = [
    {
        path: '/', redirect: '/inventory'
    },
     {
        path: '/login',
        name: 'Login',
        component: () => import('../components/Login.vue'),
        beforeEnter: (to, from, next) => {
          if (isAuthenticated()) next({name: 'Inventory'});
          else next();
        },
        meta: {
            auth: false
        }
    },
    {
        path: '/inventory',
        name: 'Inventory',
        component: () => asyncComponent(() => import('../pages/inventory/Inventory.vue')),
        meta: {
            auth: true
        }
    },
    {
        path: '/inventory/adjust',
        name: 'Adjustment',
        component: () => asyncComponent(() => import('../pages/inventory/Adjustment.vue')),
        meta: {
            auth: true
        }
    },
    {
        path: '/inventory/history',
        name: 'History',
        component: () => asyncComponent(() => import('../pages/inventory/History.vue')),
        meta: {
            auth: true
        }
    },
    {
        path: '/monitoring',
        name: 'Monitoring',
        component: () => asyncComponent(() => import('../pages/inventory/Monitoring.vue')),
        meta: {
            auth: true
        }
    },
    {
        path: '/employees',
        name: 'Employees',
        component: () => asyncComponent(() => import('../pages/employees/Employees.vue')),
        meta: {
            auth: true
        }
    },
    {
        path: '/items',
        name: 'Items',
        component: () => asyncComponent(() => import('../pages/items/Items.vue')),
        meta: {
            auth: true
        }
    },
    {
        path: '/legends',
        name: 'Legends',
        component: () => asyncComponent(() => import('../pages/legends/Legends.vue')),
        meta: {
            auth: true
        }
    }
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

router.beforeEach((to, from, next) => {
  if (to.name !== 'Login' && !isAuthenticated()) next({ name: 'Login' })
  else next()
})

export default router
