<template>
    <router-link class="sidebar-link sidebar-link-with-icon">
        <span class="sidebar-icon bg-transparent justify-content-start mr-0" :class="color">
            <i class="fa" :class="icon" aria-hidden="true"></i>
        </span>
        <slot></slot>
    </router-link>
</template>

<script>
export default {
    name: 'sidebar-link',
    props: [
        'color',
        'icon'
    ]
}
</script>