import jwt_decode from "jwt-decode";

export function isAuthenticated() {
  const token = localStorage.access_token;
  if (token) {
    const { exp } = jwt_decode(token);
    if (Date.now() >= exp * 1000) {
      return false;
    }
    return true;
  } else {
    return false;
  }
}