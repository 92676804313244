<template>
    <div>
        <router-view/>
        <!-- Toast -->
        <div class="sticky-alerts"></div>
    </div>
</template>

<script>
import * as halfmoon from 'halfmoon';

export default {
    name: 'App',
    created() {
        document.title = 'Inventory';
    },
    mounted() {
        halfmoon.onDOMContentLoaded();
        document.body.classList.add("dark-mode");
    }
}
</script>

<style>
.remove-margin-top {
    margin-top: 10px;
}

.content-wrapper {
    transition: left 0.1s;
}
</style>