import * as halfmoon from 'halfmoon';

export default {
    methods: {
        ToastSuccess(message) {
            halfmoon.initStickyAlert({
                content: message,
                alertType: "alert-success",
                fillType: "filled-lm",
                timeShown: 1000
            });
        },
        ToastError(message) {
            halfmoon.initStickyAlert({
                content: message,
                alertType: "alert-danger",
                fillType: "filled-lm",
                timeShown: 1000
            });
        }
    }
}