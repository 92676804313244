<template>
    <div class="page-wrapper with-navbar with-sidebar">
        <!-- Navbar -->
        <navbar class="navbar shadow"></navbar>
        <!-- Sidebar -->
        <sidebar class="sidebar shadow-lg"></sidebar>
        <!-- Content wrapper -->
        <div class="content-wrapper">
            <div class="content remove-margin-top">
                <slot></slot>
            </div>
        </div>
    </div>
</template>

<script>
import navbar from "./Navbar";
import sidebar from "./Sidebar";

export default {
    components: {
        navbar,
        sidebar,
    }
}
</script>
