import { createApp } from 'vue';
import App from './App.vue';
import axios from 'axios';
import VueAxios from 'vue-axios';
import VueTippy from 'vue-tippy';
import 'tippy.js/dist/tippy.css';

import 'halfmoon/css/halfmoon-variables.min.css';
import 'sweetalert2/dist/sweetalert2.css';
import '@fortawesome/fontawesome-free/css/all.css';
import '@fortawesome/fontawesome-free/js/all.js';
import './assets/css/main.css';
import router from './router';
import Layout from './components/Layout';

axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.access_token;

createApp(App)
    .use(router)
    .component('layout', Layout)
    .use(VueAxios, axios)
    .use(VueTippy, {
        defaultProps: { placement: 'top' }
    })
    .mount('#app');