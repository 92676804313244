<template>
    <div>
        <div class="sidebar-menu">
            <h5 class="sidebar-title">General</h5>
            <div class="sidebar-divider"></div>
            <sidebar-link to="/" color="text-success" icon="fa-dolly-flatbed">Inventory</sidebar-link>
            <sidebar-link to="/monitoring" color="text-primary" icon="fa-desktop">Monitoring</sidebar-link>
            <br />
            <h5 class="sidebar-title">List</h5>
            <div class="sidebar-divider"></div>
            <sidebar-link to="/employees" color="text-secondary" icon="fa-user">Employees</sidebar-link>
            <sidebar-link to="/items" color="text-danger" icon="fa-tshirt">Items</sidebar-link>
            <sidebar-link to="/legends" color="text-primary" icon="fa-clipboard-list">Legends</sidebar-link>
        </div>
    </div>
</template>

<script>
import SidebarLink from "./SidebarLink";

export default {
    name: 'sidebar',
    components: { SidebarLink }
}
</script>